import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-searchbar',
  templateUrl: './custom-mobile-searchbar.component.html',
  styleUrls: ['./custom-mobile-searchbar.component.scss'],
})
export class CustomMobileSearchbarComponent  implements OnInit {
  @Input() placeholderMobile = "";
  @Input() debounceMobile = 1000;

  @Output() searchQueryMobile: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeSearchEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() openSearchEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() searchIsActive:boolean = false;

  constructor() { }

  ngOnInit() {}

  onQueryhMobile(event) {
    this.searchQueryMobile.emit(event);
  }

  onOpenSearchbar() {
    this.openSearchEvent.emit(true);
  }

  onCloseSearchbar() {
    this.closeSearchEvent.emit(false);
  }

}
